<template>
  <v-main class="mt-0 pt-0" style="background-color: white">
    <router-view :key="$route.path" />
  </v-main>
</template>

<script>
export default {
  name: "PublicView",
};
</script>
